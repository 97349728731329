<template>
  <div
    class="related-video relative bg-no-repeat bg-cover bg-center"
    :class="{ 'opacity-50 hover:opacity-50 hover:cursor-default': isDisabled }"
    :style="{
      backgroundImage: `url('${video.teaser}')`,
    }">
    <h3 class="related-title absolute text-left left-0 right-0 bottom-0 flex flex-col justify-end p-2 text-white leading-tight">
      <div class="text-sm xs:text-xxs sm:text-sm md:text-sm pb-2px xs:pb-2px md:pb-1 truncate">
        {{ video.kicker }}
      </div>
      <div class="font-bold xs:text-xs sm:text-base md:text-lg max-lines max-lines-2">
        {{ video.title }}
      </div>
    </h3>
    <button
      :disabled="isDisabled"
      class="absolute left-0 top-0 right-0 bottom-0 w-full cursor-pointer flex items-center justify-center"
      :class="{ 'hover:cursor-default': isDisabled }"
      @click="handleClick(video)">
      <svg width="60" height="60" class="xs:w-10 xs:h-10 sm:w-14 sm:h-14 xs:-mt-6 sm:mt-0">
        <use xlink:href="#video" />
      </svg>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'related-videos',
  props: {
    video: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
    },
  },
  methods: {
    handleClick() {
      if (!this.isDisabled) {
        this.$emit('play-related', this.video.id);
      }
    },
  },
});
</script>

<style lang="postcss" scoped>
.related-video {
  padding-bottom: 56.25%;
  height: 0;
}

.related-title {
  min-height: 50%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}
</style>
