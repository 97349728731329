<template>
  <div class="related-videos">
    <template v-if="relatedVideos && relatedVideos.length > 0">
      <div class="related-carousel px-12 md:px-20 mx-auto hidden">
        <hooper :settings="hooperSettings" style="height: auto; outline: none">
          <slide v-for="video in relatedVideos" :key="video.id">
            <related-video-item :video="video" :is-disabled="isDisabled" @play-related="playRelatedVideo" />
          </slide>
          <hooper-navigation slot="hooper-addons">
            <svg slot="hooper-prev" width="32" height="32">
              <use xlink:href="#arrow-left" />
            </svg>
            <svg slot="hooper-next" width="32" height="32">
              <use xlink:href="#arrow-right" />
            </svg>
          </hooper-navigation>
        </hooper>
      </div>
      <div class="related-list xs:grid grid-cols-2 gap-4">
        <related-video-item
          v-for="video in relatedVideos"
          :key="video.id"
          class="relative mb-4 xs:mb-0"
          :video="video"
          :is-disabled="isDisabled"
          @play-related="playRelatedVideo" />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import { defineComponent } from '@vue/composition-api';
import relatedVideoItem from './relatedVideoItem.component.vue';

export default defineComponent({
  name: 'related-videos',
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    relatedVideoItem,
  },
  props: {
    relatedVideos: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 2.3,
        infiniteScroll: true,
        breakpoints: {
          768: {
            itemsToShow: 2.3,
          },
          995: {
            itemsToShow: 3.3,
          },
          1400: {
            itemsToShow: 4.3,
          },
          2000: {
            itemsToShow: 5.3,
          },
        },
      },
    };
  },
  methods: {
    playRelatedVideo(videoId: string) {
      if (!this.isDisabled) {
        this.$emit('play-related', videoId);
      }
    },
  },
});
</script>

<style lang="postcss" scoped>
/**
  Main video and carousel below
*/
@media (min-width: 768px) and (min-height: 800px) and (orientation: landscape) {
  .related-carousel {
    @apply block;
  }

  .related-list {
    @apply hidden;
  }
}
</style>

<style lang="postcss">
.related-videos .hooper-slide {
  @apply pr-4;
}

.related-videos .hooper-prev {
  @apply p-2 -ml-12;
}

.related-videos .hooper-next {
  @apply p-2 -mr-12;
}
</style>
