<template>
  <div class="video-container mx-auto px-4 xs:px-0 sm:px-5 max-w-sm sm:max-w-full pb-8">
    <button
      class="fixed bg-white z-30 left-0 top-0 w-full pt-4 pr-4 sm:pt-5 sm:pr-5 pb-1 cursor-pointer sm:text-md flex items-center justify-end"
      @click="closeVideoLayer">
      Schliessen
      <svg width="20" height="20" class="ml-2 fill-current sm:w-6 sm:h-6">
        <use xlink:href="#clear" />
      </svg>
    </button>

    <div class="video-player mx-auto bg-white sticky top-0 pt-18 pb-4 sm:pb-8 md:pb-9 z-20">
      <div class="red-shape red-shape-channel" />
      <div class="fe-video-wrapper bg-white mb-2">
        <div :id="playerDiv" class="relative w-full fe-video-player z-1" />
      </div>
      <h1 class="text-left leading-tight">
        <div class="video-kicker text-vertical truncate md:text-xl mb-1">
          {{ activeVideo && activeVideo.kicker }}
        </div>
        <div class="video-title font-bold text-md xs:text-xl md:text-5xl max-lines max-lines-2">
          {{ activeVideo && activeVideo.title }}
        </div>
      </h1>
    </div>

    <related-videos :key="activeVideoId" :related-videos="relatedVideos" :is-disabled="isDisabled" @play-related="playRelatedVideo" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from '@vue/composition-api';
import { player as playerConfig, videoEventMappings, videos as videosConfig } from '@/config/video';
import { userStore } from '@/store/user.store';
import { globalStore } from '@/store/global.store';
import TrackingUtils, { ITrackingEvent } from '@/utils/tracking/tracking.utils';
import { initBFAVideoAds } from '@/utils/ads/BFAAds';
import CommonUtils from '@/utils/common.utils';
import client from '../../api/s3Client.api';
import * as ivw from '../../utils/ivw.utils';
import relatedVideosComponent from './relatedVideos.component.vue';

/* eslint-disable no-underscore-dangle */
const webAppPlayerComposite = (closeFnc: () => any) => {
  const onWebAppBackButtonPressed = () => {
    if (document.fullscreenElement !== null) {
      // eslint-disable-next-line no-underscore-dangle
      window._play.control.interact.exitFullscreen();
      (document.fullscreenElement as any).exitFullscreen();
    } else {
      closeFnc();
    }
  };
  const onWebAppRequestFullScreen = () => {
    // eslint-disable-next-line no-underscore-dangle
    window._play.control.interact.enterFullscreen();
    const element = document.getElementById('video-player');
    if (element) element.requestFullscreen();
  };

  onMounted(() => {
    window.addEventListener('webapp-backbutton-pressed', onWebAppBackButtonPressed);
    window.addEventListener('webapp-request-fullscreen', onWebAppRequestFullScreen);
  });
  onBeforeUnmount(() => {
    window.removeEventListener('webapp-backbutton-pressed', onWebAppBackButtonPressed);
    window.removeEventListener('webapp-request-fullscreen', onWebAppRequestFullScreen);
  });

  return {
    isWebApp: globalStore.isWebapp,
    onWebAppBackButtonPressed,
    onWebAppRequestFullScreen,
  };
};

export default defineComponent({
  name: 'video-player',
  components: {
    relatedVideos: relatedVideosComponent,
  },
  props: {
    videoId: {
      type: String,
      required: true,
    },
    domainId: {
      type: String,
      default: '',
    },
    isPremium: {
      type: Boolean,
    },
  },
  setup(props, ctx) {
    const playerDiv = ref('video-player');
    const isDisabled = ref(false);
    const videos = ref<any[]>([]);
    const activeVideoId = ref<null | number>(Number(props.videoId));

    const activeVideo = computed(() => videos.value.find((video) => video.id.toString() === activeVideoId.value?.toString()));
    const relatedVideos = computed(() => videos.value.filter((video) => video.id.toString() !== activeVideoId.value?.toString()));

    const closeVideoLayer = () => ctx.emit('closeVideo', new Event('close'));
    const { isWebApp } = webAppPlayerComposite(closeVideoLayer);

    const fetchRelatedVideos = async () => {
      try {
        const { data } = await client.getRelated(parseInt(props.videoId, 10));
        /* in case nexx is down or any other error http200 is returned to be sucessfully cached
         and origin is not hit that hard. Return is status = 404 + message */
        if (data.status && data.status === 404) {
          console.warn(`Nexx loading related videos failed, error: ${data.message}`);
          videos.value = [];
        } else {
          videos.value = data.slice(0, videosConfig.numberOfRelated);
        }
      } catch (err) {
        console.log(err);
      }
    };

    const dataLayer = (currentEvent: ITrackingEvent) => {
      TrackingUtils.trackEvent({
        event: currentEvent.event,
        eventCategory: 'video',
        eventAction: currentEvent.eventAction || currentEvent.action,
        eventLabel: `${activeVideo.value.id}: ${activeVideo.value.kicker || ''}: ${activeVideo.value.title || ''}`,
        videoPlayerType: 'overlay',
        revolverplay: currentEvent.revolverplay,
      });
    };
    const changePlayer = (videoId: number) => {
      activeVideoId.value = videoId;
      window._play.control.interact.swapToMediaItem(playerDiv.value, activeVideoId.value, 'video', 0, 0);
      dataLayer({
        event: 'b_video_teaser_click',
        eventAction: 'video.teaser.click',
      });
      const image = document.getElementsByClassName('behavewidth')[0] as HTMLAnchorElement;
      if (image) {
        image.style.display = 'none';
      }
    };
    const playRelatedVideo = (videoId: number) => {
      // Move current video to the end of list
      videos.value = [...relatedVideos.value, activeVideo.value];
      changePlayer(videoId);
    };

    const initPlayer = () => {
      const videoConfig = new window._play.PlayerConfiguration({
        ...playerConfig(isWebApp),
        disableAds: false,
        playstateListeners: [
          (event: any) => {
            if (event.event === 'metadata') {
              const {
                subtitle: kicker,
                thumb: teaser,
                title,
                runtime: duration,
                ID: id,
              } = window._play.control.getCurrentMedia(playerDiv.value);
              if (!videos.value.find((video) => video.id === id)) {
                videos.value.push({ title, kicker, teaser, duration, id });
              }
              if (id && activeVideoId.value !== id) {
                activeVideoId.value = id;
              }
            }
            const eventName = event.event as keyof typeof videoEventMappings;
            const currentEvent = videoEventMappings[eventName];
            if (currentEvent && currentEvent.event === 'b_video_start') {
              if (event.data.playReason !== 'revolver') {
                ivw.pageView('video');
              }
              dataLayer({
                eventAction: currentEvent.action,
                event: currentEvent.event,
                ...(event.data.playReason === 'revolver' ? { revolverplay: 'true' } : {}),
              });
            } else if (currentEvent && currentEvent.event === 'b_video_content_progress_75') {
              dataLayer(currentEvent);
            } else if (currentEvent) {
              dataLayer(currentEvent);
            }

            if (event.event === 'adstarted' || window._play.control.instanceIsReady(playerDiv.value) === false) {
              isDisabled.value = true;
            }
            if (event.event === 'playerready' || event.event === 'adended' || event.event === 'aderror') {
              isDisabled.value = false;
            }
            if (event.event === 'error') {
              isDisabled.value = false;
            }
          },
        ],
      });
      if (window._play.config.listeners.playstate.length < 1) {
        if (isWebApp) {
          window._play.config.setPlatform(globalStore.appPlatform);
        }
      }
      window._play.config.setUserIsTrackingOptOuted(globalStore.tcString);
      console.log(`CMP: Nexx.tv TC String set ${globalStore.tcString}`);
      window._play.control.addPlayer(playerDiv.value, activeVideoId.value, 'video', videoConfig);
    };

    onMounted(async () => {
      activeVideoId.value = parseInt(props.videoId, 10);
      const videoWrapper = document.querySelector('.fe-video-wrapper');
      if (
        videoWrapper &&
        (!window.analyticsData.hasOwnProperty('b_adsEnabled') ||
          window.analyticsData.b_adsEnabled === 'true' ||
          window.analyticsData.b_adsEnabled === true)
      ) {
        initBFAVideoAds(videoWrapper.parentElement?.clientWidth, videoWrapper.parentElement?.clientHeight);
      }
      console.log('Initializing player with video id:', props.videoId);
      await initPlayer();
      fetchRelatedVideos();
    });
    onBeforeUnmount(() => {
      window._play.control.removePlayer();
    });

    watch(activeVideo, (newVal) => {
      const video = document.querySelector('video');
      if (video && newVal) {
        video.setAttribute('data-io-video-title', CommonUtils.fixedEncodeURIComponent(`${newVal.kicker}: ${newVal.title}`));
        video.setAttribute('data-io-video-id', activeVideoId.value!.toString());
      }
    });
    return {
      videos,
      isDisabled,
      activeVideoId,
      activeVideo,
      relatedVideos,
      playerDiv,
      closeVideoLayer,
      playRelatedVideo,
    };
  },
});
</script>
<style lang="postcss" scoped>
@screen sm {
  .red-shape {
    max-width: 12rem;
  }
}

/**
Fallback for screens with bad aspect ratio
 - whole page sacrolling except the close button
*/
@media (min-aspect-ratio: 6/5) {
  .video-container {
    max-width: 80vw;
  }

  .video-player {
    position: relative;
  }

  .video-title {
    line-height: 1.15;
  }
}

@media (min-width: 768px) and (min-aspect-ratio: 6/5) {
  .video-container {
    max-width: 996px;
  }
}

@media (max-width: 1200px) and (min-aspect-ratio: 6/5) {
  .video-player {
    @apply pb-4;
  }

  .video-kicker {
    @apply text-md leading-none mb-2px;
  }

  .video-title {
    @apply truncate text-2xl;
  }
}

/**
Main video and carousel below editor-ui-layout, only for bigger landscape
  - Magic constant 0.9 is a ratio for calculation relation between minimum viewport height to avoid scrollbars.
    It should works for all resolutions, for higher res. is it nearly to 0.95, but lower is safer.
    Trust me :)
*/
@media (min-width: 768px) and (min-height: 800px) and (orientation: landscape) {
  .video-container {
    max-width: 1900px;
    padding-bottom: 0;
  }

  .video-player {
    max-width: calc(100vh * 0.9);
    position: relative;
  }

  .video-title {
    line-height: 1.15;
    min-height: 5.25rem;
  }
}
</style>
<style>
/* not scoped, will be inside video player */
.fe-video-player > .cl_nxp_sector {
  background-color: #000 !important;
}
</style>
